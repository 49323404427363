import React from 'react'
import { FaTwitter } from 'react-icons/fa';
import { FaYoutube } from 'react-icons/fa';

const Layout = ({children}) => {
    const linkStyle = `
    inline-block
    mx-1
    align-middle
    `
    const mobileLinkStyle=`
    m-4
    p-0
    `

    const logoStyle=`
    text-black 
    font-header
    sm:text-base
    text-sm
    `
    const footerLinkStyle=`
    flex
    flex-col
    no-underline
    hover:underline
    `
    return (
        <div>
            <header id="header" className="site-header sticky block w-full top-0 m-0 px-2 pt-6 pb-3 text-md z-10">
                <span className="w-full flex justify-between items-center pb-20">
                    <div className="ml-0 text-2xl font-medium pl-4 bg-dlwhite bg-opacity-50">
                        <a className="hover:text-dlblack" href="https://friendliest.app">Friendliest.app</a>
                    </div>
                    <input className="mobile_nav_checkbox hidden" type="checkbox" id="mobile_nav_checkbox"></input>
                    <label className="mobile_nav_button lg:hidden text-md cursor-pointer m-0 p-0 z-30" for="mobile_nav_checkbox">
                        <span className="navicon"></span>
                    </label>
                    <ul className="top_nav_list hidden lg:block m-0 pr-4 font-medium">
                        <li className={linkStyle}><a href="https://friendliest.app/about">About</a></li>
                        <li className={linkStyle}><a href="https://friendliest.app/pricing">Pricing</a></li>
                        <li className={linkStyle}><a href="/">Blog</a></li>
                    </ul>
                    <nav className="mobile_nav lg:hidden fixed block inset-0 m-0 p-8 text-left font-medium z-10 opacity-95">
                        <ul className="m-0 p-0 text-xl list-none">
                            <li className={mobileLinkStyle}><a href="https://friendliest.app/about">About</a></li>
                            <li className={mobileLinkStyle}><a href="https://friendliest.app/pricing">Pricing</a></li>
                            <li className={mobileLinkStyle}><a href="/">Blog</a></li>
                        </ul>
                    </nav>
                </span>
            </header>

            <main className="max-w-screen-md mx-auto">
                <section className="standard">
                    {children}
                </section>
            </main>

            <footer className="footer mx-auto pt-4">
                <div className="container mx-auto px-6 max-w-screen-md">
                    <div className="sm:flex">
                        <div className="sm:mt-0 sm:w-full sm:px-8 flex flex-col md:flex-row justify-between text-sm">
                            <div className={footerLinkStyle}><span className="my-2"><a className="no-underline hover:underline" href="https://friendliest.app/privacy">Privacy</a></span></div>
                            <div className={footerLinkStyle}><span className="my-2"><a className="no-underline hover:underline" href="https://friendliest.app/technology">Technology</a></span></div>
                            <div className={footerLinkStyle}><span className="my-2"><a className="no-underline hover:underline" href="https://friendliest.app/data">Data</a></span></div>
                            <div className={footerLinkStyle}><span className="my-2"><a className="no-underline hover:underline" href="https://friendliest.app/support">Support</a></span></div>
                            <div className={footerLinkStyle}><span className="my-2"><a className="no-underline hover:underline" href="https://friendliest.app/contact">Contact</a></span></div>
                            <div className={footerLinkStyle}><span className="my-2"><a className="no-underline hover:underline" href="https://friendliest.app/disclaimer">Disclaimer</a></span></div>
                        </div>
                    </div>
                </div>
                <div className="container mx-auto px-6 max-w-screen-md">
                    <div className="flex flex-col items-center">
                        <div className="sm:w-2/3 text-center py-6">
                            <p className="text-sm text-700 mb-2">
                                Copyright 2021 <a className="pl-2 no-underline hover:underline" href="https://friendliest.app">Friendliest.app</a>. All rights reserved.<span className="px-2"><a href="https://twitter.com/FriendliestApp" ><FaTwitter /></a></span><span className="px-2"><a href="https://www.youtube.com/channel/UCYsILM2wXUy2SMwHf4lew2w"><FaYoutube /></a></span><br/>
                                ---<br/>
                                <a className="no-underline hover:underline" href="https://friendliest.app">Friendliest.app</a> started as a <a target="_blank" rel="noreferrer noopener" className="no-underline hover:underline" href="https://www.100daysofcode.com">#100DaysofCode</a> project.<br/>
                                ---<br/>
                                <a className="no-underline hover:underline" href="https://friendliest.app">Friendliest.app</a> is not affiliated with, endorsed by, or in any other way associated with <a target="_blank" rel="noreferrer noopener" className="no-underline hover:underline" href="https://disneyland.disney.go.com/">Disneyland</a> or <a target="_blank" rel="noreferrer noopener" className="no-underline hover:underline" href="https://thewaltdisneycompany.com/">The Walt Disney Company</a>. For official information visit <a target="_blank" rel="noreferrer noopener" className="no-underline hover:underline" href="https://disneyland.disney.go.com/">https://disneyland.disney.go.com/</a> and <a target="_blank" rel="noreferrer noopener" className="no-underline hover:underline" href="https://thewaltdisneycompany.com/">https://thewaltdisneycompany.com/</a>.<br/>
                                ---<br/>
                                <a className="no-underline hover:underline" href="https://status.friendliest.app">uptime</a>
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

if (typeof window !== `undefined`) {
    window.onscroll = function() {scrollFunction()};
}
    
function scrollFunction() {
  if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
    document.getElementById("header").classList.add('header--scrolled');
  } else {
    document.getElementById("header").classList.remove('header--scrolled');
  }
}

export default Layout